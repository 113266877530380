import type { SbComponent } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Icon, IconName } from '@/common/icon';
import { BlankLink } from '@/elements';
import { x } from '@/style';

const contactDetails: Array<{
  icon: IconName;
  title: string;
  contactInfo: string;
  href: string;
}> = [
  {
    icon: 'emailSending',
    title: 'General Enquiries',
    contactInfo: 'hi@housecure.co.uk',
    href: 'mailto:hi@housecure.co.uk',
  },
  {
    icon: 'newspaper',
    title: 'Press & Media',
    contactInfo: 'media@housecure.co.uk',
    href: 'mailto:media@housecure.co.uk',
  },
  {
    icon: 'briefcase',
    title: 'Careers',
    contactInfo: 'careers@housecure.co.uk',
    href: 'mailto:careers@housecure.co.uk',
  },
  {
    icon: 'calling',
    title: 'Call Us',
    contactInfo: '0808 502 2630',
    href: 'tel:08085022630',
  },
];

const ContactInformationSection: SbComponent = ({ content }) => (
  <SbEditable content={content}>
    <x.div
      display='grid'
      mx='auto'
      py='xl'
      px='md'
      gap='lg'
      maxW={{ _: '380px', tablet: 'section-width' }}
      gridTemplateColumns={{ _: 1, tablet: 2 }}
    >
      {contactDetails.map(({ icon, title, contactInfo, href }) => (
        <BlankLink href={href}>
          <x.span display='flex' alignItems='center'>
            <Icon size='60px' name={icon} color='brand-orange' />
            <x.span
              display='flex'
              flexDirection='column'
              justifyContent='center'
              ml='md'
            >
              <x.span
                textTransform='uppercase'
                color='brand-orange'
                fontWeight='500'
                pb='xs'
              >
                {title}
              </x.span>
              <x.span>{contactInfo}</x.span>
            </x.span>
          </x.span>
        </BlankLink>
      ))}
    </x.div>
  </SbEditable>
);

export default ContactInformationSection;
