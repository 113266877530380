import type { Link, SbComponent, SbRichText } from 'storyblok';

import SbEditable from 'storyblok-react';

import { Section } from '@/common/Layout';
import Palette from '@/common/Palette';
import {
  RichText,
  Icon,
  RichSentence,
  TRichSentence,
  TIconName,
} from '@/common/storyblok/components';
import * as e from '@/elements';
import { AnimateOnVisible, Fade } from '@/lib/utils/animation';
import { x } from '@/style';

export const SmallCardsSection: SbComponent<{
  title: TRichSentence;
  description: SbRichText;
  cards: Array<{
    _uid: string;
    link: Link;
    icon: TIconName;
    title: string;
    hasDescription: boolean;
    description: TRichSentence;
  }>;
}> = ({ content }) => (
  <SbEditable content={content}>
    <Palette palette='lightRed'>
      <Section>
        <AnimateOnVisible>
          <e.Layout.VStack my='lg'>
            <e.Layout.VStack
              textAlign='center'
              mx='auto'
              maxW='section-width-sm'
            >
              {content.subtitle ? (
                <Fade.Up>
                  <e.Text variant='subtitle1' as={x.h3} color='palette-primary'>
                    {content.subtitle}
                  </e.Text>
                </Fade.Up>
              ) : null}
              <Fade.Up>
                <RichSentence variant='h2'>{content.title}</RichSentence>
              </Fade.Up>
              <Fade.Up>
                <x.div my='md'>
                  <RichText>{content.description}</RichText>
                </x.div>
              </Fade.Up>
            </e.Layout.VStack>
            <e.Layout.Stack
              mx='auto'
              w='100%'
              flexDirection={{
                _: 'column',
                tablet: 'row',
              }}
              alignItems={{
                _: 'center',
                tablet: 'stretch',
              }}
            >
              {content.cards.map((card) => (
                <Fade.Up key={card._uid} flex='1' maxW='280px' w='100%'>
                  <e.UnstyledLink
                    href={card.link.url}
                    textDecoration='none'
                    color='unset'
                    h='100%'
                  >
                    <e.Card p='sm' h='100%'>
                      <e.Layout.VStack
                        textAlign='center'
                        alignItems='center'
                        gap='xs'
                      >
                        <Icon
                          color='palette-primary'
                          name={card.icon}
                          py='md'
                          size={32}
                        />
                        <e.Text variant='h4'>{card.title}</e.Text>
                        {card.hasDescription ? (
                          <RichSentence>{card.description}</RichSentence>
                        ) : null}
                      </e.Layout.VStack>
                    </e.Card>
                  </e.UnstyledLink>
                </Fade.Up>
              ))}
            </e.Layout.Stack>
          </e.Layout.VStack>
        </AnimateOnVisible>
      </Section>
    </Palette>
  </SbEditable>
);
